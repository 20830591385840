// extracted by mini-css-extract-plugin
export var active = "features-module--active--9723d";
export var check = "features-module--check--e0963";
export var designImage = "features-module--design-image--77b1f";
export var featureBtn = "features-module--feature-btn--5705d";
export var featureBtns = "features-module--feature-btns--623ac";
export var featureImage = "features-module--feature-image--a7bfe";
export var features = "features-module--features--b3713";
export var frame = "features-module--frame--ed66d";
export var small = "features-module--small--5c21b";
export var support = "features-module--support--f2a8d";
export var tableItem = "features-module--table-item--88b50";
export var times = "features-module--times--9f15a";